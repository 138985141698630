import * as React from 'react';
import { graphql, HeadFC, PageProps } from 'gatsby';
import SEO from '../components/SEO/SEO';
import Layout from '../layouts/Layout';
import Hero from '../components/Hero/Hero';
import ContentRepeater from '../components/ContentRepeater/ContentRepeater';
import { ContentfulPage } from '../types/contentful/PageTypes/ContentfulPage/ContentfulPage';
import { SiteMetadata } from '../types/gatsby/SiteMetadata';

interface Data {
    site: SiteMetadata;
    contentfulPage: ContentfulPage;
}

interface Props extends PageProps {
    data: {
        contentfulPage: ContentfulPage;
    }
}

const ReferenceTablePage = ({ data: { contentfulPage } }: Props) => {
    return (
        <Layout>
            <Hero
                title={contentfulPage?.pageTitle}
                description={contentfulPage?.pageDescription}
                image={contentfulPage?.pageImage}
            />
            <ContentRepeater content={contentfulPage?.content} />

        </Layout>
    );
};

export default ReferenceTablePage;

// gatsby page query
export const pageQuery = graphql`
    query ReferenceTablePageQuery {
        site {
            siteMetadata {
                title
                description
                url
                image
            }
        }
        contentfulPage(internalName: {eq: "Reference Table Page"}) {
            ...pageFields
            ...pageContent
        }
    }
`;

export const Head: HeadFC = ({ location, data }) => {
    const seoData = data as Data;

    return (
        <SEO data={seoData} location={location} />
    );
};
